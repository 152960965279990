export async function getBuildId() {
  try {
    const req = await fetch(`${process.env.MESSAGING_API}/get-build-id`, {
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
    console.log(req);
    return req;
  } catch (err) {
    console.log(err);
  }
}
