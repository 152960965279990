import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import weekday from "dayjs/plugin/weekday";
import isoWeek from "dayjs/plugin/isoWeek";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(weekday);
dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.extend(tz);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

export function formatEventTime(date) {
  return dayjs(date).format("h:mm a");
}

export function formatEventTimeNoSpace(date) {
  return dayjs(date).format("h:mma");
}

export function formatEventTimeTwoDigits(date) {
  return dayjs(date).format("hh:mm");
}

export function formatTimezone(date) {
  return dayjs(date).format("z");
}

export function formatDay(date) {
  return dayjs(date).format("D");
}

export function formatWeekDay(date) {
  return dayjs(date).format("ddd");
}

export function formatWeekDayFull(date) {
  return dayjs(date).format("dddd");
}

export function formatMonth(date) {
  return dayjs(date).format("MMM");
}

export function formatYear(date) {
  return dayjs(date).format("YYYY");
}


export function formatMonthFull(date) {
  return dayjs(date).format("MMMM");
}

export function getWeekFirstDay(date) {
  return dayjs(date).isoWeekday(1).format();
}

export function getWeekDay(date) {
  return dayjs(date).weekday();
}

export function getDayByWeek(date) {
  return dayjs(date).week();
}

export function formatDayWithMonth(date) {
  return dayjs(date).format("D MMMM");
}

export function formatDayWithMonthShort(date) {
  return dayjs(date).format("D MMM");
}

export function formatMonthWithYear(date) {
  return dayjs(date).format("MMM YYYY");
}

export function formatDayWithMonthWithYearShort(date) {
  return dayjs(date).format("D MMM YY");
}

export function getDuration(start, end) {
  return dayjs(end).diff(dayjs(start), "m");
}

export function getDurationMonth(start) {
  return dayjs(start).fromNow(true)
}

export function getDurationDay(start, end) {
  return dayjs(end).diff(dayjs(start), "d");
}

export function getDurationHour(start, end) {
  return dayjs(end).diff(dayjs(start), "h");
}

export function getDurationHourFloat(start, end) {
  return dayjs(end).diff(dayjs(start), "h", true);
}

export function getTime(date) {
  return dayjs(date).format("hh:mm A");
}
export function getDate(date) {
  return dayjs(date).format('DD/MM/YYYY');
}
export function getDateCalendar(date) {
  return dayjs(date).format('YYYY/DD/MM');
}
export function getDayOfWeek(date, i) {
  return dayjs(date).weekday(i);
}

export function getWeekOfYear(date) {
  return dayjs(date).week();
}

export function get24Hour(date) {
  return dayjs(date).format("H");
}
export function getMinutes(date) {
  return dayjs(date).format("m");
}
export function getDateUtc(date) {
  return dayjs(date).utc().format();
}

export function getYesterday(date) {
  return dayjs(date).subtract(1, 'day').toString()
}

export function isAfter(date) {
  return dayjs(date).isAfter(dayjs(), 'day')
}
export function isToday(date) {
  return dayjs(date).isSame(dayjs(), 'day')
}
