import { authStore } from "../authStore";
import { fetchRetryWrapper } from "./fetch-retry-wrapper.js";

export async function getStartup() {
  console.log(
    authStore.token.value,
    "tokenCheckWithServer authStore.token.value"
  );

  try {
    const response = await fetch(`${process.env.API_URL}/api/users/me`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${authStore.token.value}`,
      },
    }).then((res) => res.json());
    return response;
  } catch (err) {
    console.log(err);
  }
}

export function getStartupInfo() {
  console.log("getStartup called");

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${authStore.token.value}`,
    },
  };

  let url = `${process.env.API_URL}/api/users/me`;

  return fetchRetryWrapper(url, options);
}

export async function tokenCheckWithServer() {
  console.log("tokenCheckWithServer called");

  return getStartupInfo().subscribe({
    next: async (response) => {
      console.log("tokenCheckWithServer RESPONSE", response);
      if (!response || response.user === null) {
        alert("You need to logout and login. Sorry :(");
        window.location = "/logout";
      } else {
        //MIGHT WANT TO SAVE THE USER DATA from res BECAUSE WHEN USER REFRESH FOR CHANGES
        console.log("USER IS LOGGED IN - TOKEN EXISTS", response);

        if (response?.user) {
          // update authStore values
          authStore.userObject.value = response?.user;
          console.log(
            "tokenCheckWithServer -> update authStore value: authStore.userObject.value",
            authStore.userObject.value
          );
          if (response?.user?.role === "startup") {
            //  update founder info
            if (authStore.founderId.value) {
              let currentFounder = response.user?.founders?.find(
                (item) => item.id === authStore.founderId.value
              );
              if (!currentFounder) return;
              authStore.founderObject.value = currentFounder;
              console.log(
                "tokenCheckWithServer -> update authStore value: authStore.founderObject.value",
                authStore.founderObject.value
              );
            }
          }
        }
      }
    },
    error: (err) => {
      console.log(err, "ERROR IN tokenCheckWithServer");
      alert("You need to logout and login. Sorry :(");
      window.location = "/logout";
    },
  });
}
